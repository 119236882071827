import apiclient from '../apiclient';

let cache = {};
let clicked = null;
let timeout = null;

export async function request (linkElement, url, lang = 'en') {
    let data = cache[linkElement.href];
    if (!data) {
        let response = await apiclient.get(url + '?lang=' + lang);
        data = response.data;
        cache[linkElement.href] = data;
    }

    return data;
}

export function armorSet(data) {
    let html = '' +
        '<div class="center-block" style="max-width: 100%; height: 100%;">' +
        '<div class="set-tooltip" style="height: 100%;">' +
        '<div class="set-tooltip-center">' +
        '<div class="set-tooltip-header">' +
        '<strong>'+data['category']+'</strong> <strong>Level 50 - CP 160</strong>' +
        '</div>' +
        '<picture><source type="image/webp" srcset="https://eso-hub.com/storage/icons/'+data['icon_webp']+'"> ' +
        '<source type="image/png" srcset="https://eso-hub.com/storage/icons/'+data['icon']+'"> ' +
        '<img src="https://eso-hub.com/storage/icons/'+data['icon']+'" title="'+data['name']+'" alt="Icon"></picture> ' +
        '<h4 class="set-tooltip-setname">'+data['name']+'</h4>' +
        '<div class="set-tooltip-seperator"></div> ';
    if (data['bonus_1'] !== null) {
        html += '<span><strong class="set-bonus">(1 item)</strong> '+data['bonus_1']+'</span><br> ';
    }
    if (data['bonus_2'] !== null) {
        html += '<span><strong class="set-bonus">(2 items)</strong> '+data['bonus_2']+'</span><br> ';
    }
    if (data['bonus_3'] !== null) {
        html += '<span><strong class="set-bonus">(3 items)</strong> '+data['bonus_3']+'</span><br> ';
    }
    if (data['bonus_4'] !== null) {
        html += '<span><strong class="set-bonus">(4 items)</strong> '+data['bonus_4']+'</span><br> ';
    }
    if (data['bonus_5'] !== null) {
        html += '<span><strong class="set-bonus">(5 items)</strong> '+data['bonus_5']+'</span><br> ';
    }
    if (data['bonus_6'] !== null) {
        html += '<span><strong class="set-bonus">(6 items)</strong> '+data['bonus_6']+'</span><br> ';
    }
    if (data['bonus_7'] !== null) {
        html += '<span><strong class="set-bonus">(7 items)</strong> '+data['bonus_7']+'</span><br> ';
    }
    if (data['bonus_8'] !== null) {
        html += '<span><strong class="set-bonus">(8 items)</strong> '+data['bonus_8']+'</span><br> ';
    }
    if (data['bonus_9'] !== null) {
        html += '<span><strong class="set-bonus">(9 items)</strong> '+data['bonus_9']+'</span><br> ';
    }
    if (data['bonus_10'] !== null) {
        html += '<span><strong class="set-bonus">(10 items)</strong> '+data['bonus_10']+'</span><br> ';
    }
    if (data['bonus_11'] !== null) {
        html += '<span><strong class="set-bonus">(11 items)</strong> '+data['bonus_11']+'</span><br> ';
    }
    if (data['bonus_12'] !== null) {
        html += '<span><strong class="set-bonus">(12 items)</strong> '+data['bonus_12']+'</span><br> ';
    }
    html += '<br><small style="float: right">Tooltips by ESO-Hub.com</small></div></div></div>';

    return  html;
}

export function skill(data) {
    let html = '' +
        '<div class="set-tooltip"><div class="set-tooltip-center">' +
        '<img src="https://eso-hub.com/storage/icons/' + data['icon'] + '" title="' + data['name'] + '" alt="Icon" class="skill-img ' + (data['passive'] ? 'passive-skill' : '') + ' "> ' +
        '<h4 class="set-tooltip-setname">' + data['name'] + '</h4></a> ' +
        '<div class="set-tooltip-seperator"></div> ' +
        '<strong>Effect</strong><br>' + data['effect_1'];
    if (data['effect_2'] !== null) {
        html += '<br> <span class="skill-morph-effect"><strong>New effect</strong><br>' + data['effect_2'] + '</span>';
    }

    html += '<br><small style="float: right">Tooltips by ESO-Hub.com</small></div></div>';

    return  html;
}

export function collectible(data) {
    let html = '' +
        '<div class="set-tooltip"><div class="set-tooltip-center">' +
        '<img src="https://eso-hub.com/storage/icons/' + data['icon'] + '" title="' + data['name'] + '" alt="Icon"> ' +
        '<h4 class="set-tooltip-setname">' + data['name'] + '</h4></a> ' +
        '<div class="set-tooltip-seperator"></div> ' + data['description'];
    if (data['hint'] !== null) {
        html += '<div class="set-tooltip-seperator"></div> ' + data['hint'];
    }
    html += '<br><small style="float: right">Tooltips by ESO-Hub.com</small></div></div>';

    return  html;
}

export function championPointStar(data) {
    return '<div class="set-tooltip"><div class="set-tooltip-center">' +
        '<div class="set-tooltip-header"><strong>' + data['discipline'] + '</strong>' +
        (data['cluster'] ? ('<strong>'+ data['cluster'] + '</strong>') : '') +
        '</div>' +
        '<img src="https://eso-hub.com/storage/' + data['icon'] + '" title="' + data['name'] + '" alt="Icon"> ' +
        '<h4 class="set-tooltip-setname">' + data['name'] + '</h4></a> ' +
        '<div class="set-tooltip-seperator"></div> ' + data['description'] + '<br><small style="float: right">Tooltips by ESO-Hub.com</small></div></div>';
}

export function companionSkill(data) {
    return '<div class="set-tooltip"><div class="set-tooltip-center">' +
        '<div class="set-tooltip-header"><strong>' + data['skillLine'] + '</strong>' +
        '</div>' +
        '<img src="https://eso-hub.com/storage/icons/' + data['icon'] + '" title="' + data['name'] + '" alt="Icon" class="skill-img ' + (data['passive'] ? 'passive-skill' : '') + ' "> ' +
        '<h4 class="set-tooltip-setname">' + data['name'] + '</h4></a> ' +
        '<div class="set-tooltip-seperator"></div> ' + data['description'] + '<br><small style="float: right">Tooltips by ESO-Hub.com</small></div></div>';
}

export function food(data) {
    return '<div class="set-tooltip"><div class="set-tooltip-center">' +
        '<div class="set-tooltip-header"><strong>' + data['category'] + '</strong>' +
        '</div>' +
        '<img src="https://eso-hub.com/storage/icons/' + data['icon'] + '" title="' + data['name'] + '" alt="Icon"> ' +
        '<h4 class="set-tooltip-setname quality-'+data['quality']+'">' + data['name'] + '</h4></a> ' +
        '<div class="set-tooltip-seperator"></div> ' + data['description'] + '<br><small style="float: right">Tooltips by ESO-Hub.com</small></div></div>';
}

export function companionGear(data) {
    return '<div class="set-tooltip"><div class="set-tooltip-center">' +
        '<div class="set-tooltip-header"><strong>' + (data['weight'] ?? data['weapon_type']) + ' ' + data['item'] + '</strong>' +
        '</div>' +
        '<img src="https://eso-hub.com/storage/icons/' + data['icon'] + '" title="' + data['name'] + '" alt="Icon"> ' +
        '<h4 class="set-tooltip-setname quality-4">' + data['name'] + '</h4></a> ' +
        '<div class="set-tooltip-seperator"></div> ' + data['description'] + '<br><small style="float: right">Tooltips by ESO-Hub.com</small></div></div>';
}

export function furniture(data) {
    return '<div class="set-tooltip"><div class="set-tooltip-center">' +
        '<div class="set-tooltip-header"><strong>' + data['category'] + '</strong>' +
        '</div>' +
        '<img src="https://eso-hub.com/storage/icons/' + data['icon'] + '" title="' + data['name'] + '" alt="Icon"> ' +
        '<h4 class="set-tooltip-setname quality-'+data['quality']+'">' + data['name'] + '</h4></a> ' +
        '<div class="set-tooltip-seperator"></div> ' + data['description'] + '<br><small style="float: right">Tooltips by ESO-Hub.com</small></div></div>';
}

export function glyph(data) {
    return '<div class="set-tooltip"><div class="set-tooltip-center">' +
        '<div class="set-tooltip-header"><strong>' + data['type'] + '</strong>' +
        '</div>' +
        '<img src="https://eso-hub.com/storage/icons/' + data['icon'] + '" title="' + data['name'] + '" alt="Icon"> ' +
        '<h4 class="set-tooltip-setname">' + data['name'] + '</h4></a> ' +
        '<div class="set-tooltip-seperator"></div> ' + data['description'] + '<br><small style="float: right">Tooltips by ESO-Hub.com</small></div></div>';
}

export function totCard(imgUrl) {
    return '<img src="https://eso-hub.com/storage/' + imgUrl + '">';
}

export function positionTooltip(div, event) {

    const xTranslate = event.clientX > window.innerWidth / 2 ? 'calc(-100% - 15px)' : '15px';
    const yTranslate = event.clientY > window.innerHeight / 2 ? 'calc(-100% - 15px)' : '15px';

    div.style.width = "350px";
    div.style.position = 'absolute';
    div.style.left = event.pageX + 'px';
    div.style.top = event.pageY + 'px';
    div.style.zIndex = "99999";

    if(window.innerWidth < 700){
        div.style.left = window.innerWidth / 2 + 'px';
        div.style.transform = 'translate(-50%, 15px)';
    }    
    else
        div.style.transform = `translate(${ xTranslate }, ${ yTranslate })`;

}

const throttle = (fn, delay) => {

    if(timeout) return;
    fn();

    timeout = setTimeout(() => timeout = null, delay);
}

export function addGenericListeners(linkElement) {

    linkElement.addEventListener('mousemove', (e) => throttle(() => {
        const element = document.getElementById(linkElement.href);
        if(element)
            positionTooltip(element, e);
    }, 20));

    linkElement.addEventListener('mouseleave', (e) => {
        clicked = null;
        timeout = null;
        let element = document.getElementById(linkElement.href);
        if (element)
            element.remove();
    });

    if (isMobile()) {
        linkElement.addEventListener('click', (e) => {
            if (linkElement.href === clicked) {
                window.location.href = linkElement.href;
                return;
            }
            clicked = linkElement.href;
            e.preventDefault();
            e.stopPropagation();
        }, false);
    }
}

export function isMobile() {

    return navigator.userAgent.match(/Android/i)
         || navigator.userAgent.match(/webOS/i)
         || navigator.userAgent.match(/iPhone/i)
         || navigator.userAgent.match(/iPad/i)
         || navigator.userAgent.match(/iPod/i)
         || navigator.userAgent.match(/BlackBerry/i)
         || navigator.userAgent.match(/Windows Phone/i)

}