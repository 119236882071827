import {
    armorSet,
    skill,
    request,
    collectible,
    championPointStar,
    companionSkill,
    food,
    companionGear, positionTooltip, addGenericListeners, furniture, glyph, totCard, isMobile
} from '../libs/tooltip-utils';

document.addEventListener('DOMContentLoaded', function () {
    addEsoHubToolTipsToAll();
    window.addEsoHubToolTipsToAll = addEsoHubToolTipsToAll;
    window.addEsoHubToolTip = addEsoHubToolTip;
});

function addEsoHubToolTipsToAll() {
    let links = document.getElementsByTagName('a');
    for(let link of links)
        addEsoHubToolTip(link);
}

const addEsoHubToolTip = (link) => {

    if(link.href === window.location.href) return;
    if(!link.href?.length) return;
    
    const url = new URL(link.href);
    if (url.hostname !== 'eso-hub.com' && url.hostname !== 'eso-hub.ddev.site') return;
    
    let match = link.href.match(/\/(en|de|fr|ru|es)\//g);
    if(!match?.length) return;
    
    const lang = match.pop().replace(/\//g, '');
    tooltips.forEach(tooltip => {

        if(!tooltip.regex.test(link.href)) return;

        link.addEventListener('mouseenter', (e) => {

            let element = document.getElementById(link.href);
            if (element)
                element.remove();

            tooltip.fn(link, e, lang);
            addGenericListeners(link);
        });
    });
        
}

const renderTooltip = (linkElement, e, tooltipContent) => {

    if(!e.target.matches(':hover') && !isMobile()) return;

    const div = document.createElement("div");
    div.id = linkElement.href;
    div.innerHTML = tooltipContent;
    positionTooltip(div, e);
    document.body.appendChild(div);
}

const buildGlyphToolTip = (link, e, lang = 'en') => buildGlyph(link, e, lang, false);
const buildGlyphLevelToolTip = (link, e, lang = 'en') => buildGlyph(link, e, lang, true);

const buildSetToolTip = async (linkElement, e, lang = 'en') => {
    const data = await request(linkElement, 'https://eso-hub.com/api/armor-sets/tooltip/' + linkElement.href.split('/sets/').pop(), lang);
    const tooltipContent = armorSet(data);
    renderTooltip(linkElement, e, tooltipContent);
}

const buildSkillToolTip = async (linkElement, e, lang = 'en') => {
    const data = await request(linkElement, 'https://eso-hub.com/api/skills/tooltip/' + linkElement.href.split('/').pop(), lang);
    const tooltipContent = skill(data);
    renderTooltip(linkElement, e, tooltipContent);
}

const buildCollectibleToolTip = async(linkElement, e, lang = 'en') => {
    const data = await request(linkElement, 'https://eso-hub.com/api/collectibles/tooltip/' + linkElement.href.split('/').pop(), lang);
    const tooltipContent = collectible(data);
    renderTooltip(linkElement, e, tooltipContent);
}

const buildChampionPointStarToolTip = async (linkElement, e, lang = 'en') => {
    const data = await request(linkElement, 'https://eso-hub.com/api/champion-points/star/tooltip/' + linkElement.href.split('/').pop(), lang);
    const tooltipContent = championPointStar(data);
    renderTooltip(linkElement, e, tooltipContent);
}

const buildCompanionSkillToolTip = async (linkElement, e, lang = 'en') => {
    const data = await request(linkElement, 'https://eso-hub.com/api/companions/skill/tooltip/' + linkElement.href.split('/').pop(), lang);
    const tooltipContent = companionSkill(data);
    renderTooltip(linkElement, e, tooltipContent);
}

const buildFoodTooltip = async (linkElement, e, lang = 'en') => {
    const data = await request(linkElement, 'https://eso-hub.com/api/food-drinks/tooltip/' + linkElement.href.split('/').pop(), lang);
    const tooltipContent = food(data);
    renderTooltip(linkElement, e, tooltipContent);
}

const buildCompanionGearToolTip = async (linkElement, e, lang = 'en') => {
    const data = await request(linkElement, 'https://eso-hub.com/api/companions/gear/tooltip/' + linkElement.href.split('/').pop(), lang);
    const tooltipContent = companionGear(data);
    renderTooltip(linkElement, e, tooltipContent);
}

const buildFurnitureToolTip = async (linkElement, e, lang = 'en') => {
    const data = await request(linkElement, 'https://eso-hub.com/api/furniture/tooltip/' + linkElement.href.split('/').pop(), lang);
    const tooltipContent = furniture(data);
    renderTooltip(linkElement, e, tooltipContent);
}

const buildGlyph = async (linkElement, e, lang = 'en', isLevel = false) => {
    const data = await request(linkElement, `https://eso-hub.com/api/glyphs/${ isLevel ? 'level/' : '' }tooltip/` + linkElement.href.split('/').pop(), lang);
    const tooltipContent = glyph(data);
    renderTooltip(linkElement, e, tooltipContent);
}

const buildTotCardToolTip = async (linkElement, e, lang = 'en') => {
    const tooltipContent = totCard('tot-cards/'+lang+'/'+linkElement.href.split('/').pop()+'.png');
    renderTooltip(linkElement, e, tooltipContent);
}

const tooltips = [
    { fn: buildSetToolTip, regex: new RegExp('^https:\/\/[a-z0-9-.]*\/(en|de|fr|ru|es)\/sets\/[a-z0-9-]*$') },
    { fn: buildSkillToolTip, regex: new RegExp('^https:\/\/[a-z0-9-.]*\/(en|de|fr|ru|es)\/skills\/[a-z0-9-]*\/[a-z0-9-]*\/[a-z0-9-]*$') },
    { fn: buildCollectibleToolTip, regex: new RegExp('^https:\/\/[a-z0-9-.]*\/(en|de|fr|ru|es)\/collectibles\/[a-z0-9-]*$') },
    { fn: buildChampionPointStarToolTip, regex: new RegExp('^https:\/\/[a-z0-9-.]*\/(en|de|fr|ru|es)\/champion\-points\/star\/[a-z0-9-]*$') },
    { fn: buildCompanionSkillToolTip, regex: new RegExp('^https:\/\/[a-z0-9-.]*\/(en|de|fr|ru|es)\/companions\/skills\/[a-z0-9-]*\/[a-z0-9-]*\/[a-z0-9-]*$') },
    { fn: buildFoodTooltip, regex: new RegExp('^https:\/\/(www\.)?eso-hub\.com\/(en|de|fr|ru|es)\/food\-drinks\/[a-z0-9-]*\/[a-z0-9-]*$') },
    { fn: buildCompanionGearToolTip, regex: new RegExp('^https:\/\/(www\.)?eso-hub\.com\/(en|de|fr|ru|es)\/companions\/gear\/[a-z0-9-]*$') },
    { fn: buildFurnitureToolTip, regex: new RegExp('^https:\/\/(www\.)?eso-hub\.com\/(en|de|fr|ru|es)\/furniture\/[a-z0-9-]*$') },
    { fn: buildGlyphToolTip, regex: new RegExp('^https:\/\/(www\.)?eso-hub\.com\/(en|de|fr|ru|es)\/enchanting\-runes\-and\-glyphs\/glyph\/[a-z0-9-]*$') },
    { fn: buildGlyphLevelToolTip, regex: new RegExp('^https:\/\/(www\.)?eso-hub\.com\/(en|de|fr|ru|es)\/enchanting\-runes\-and\-glyphs\/glyph\/[a-z0-9-]*\/[a-z0-9-]*$') },
    { fn: buildTotCardToolTip, regex: new RegExp('^https:\/\/(www\.)?eso-hub\.com\/(en|de|fr|ru|es)\/tales\-of\-tribute\/(?!clues)[a-z0-9-]*\/[a-z0-9-]*$') }
];
